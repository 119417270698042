export const discussionList = [
    {
        holder: 'OS',
        discussions: [
            {
                date: '05/09/2019',
                topic: 'Computer Architecture: Overview',
                resources: [
                    {
                        link:'https://www.howtogeek.com/398493/what-exactly-happens-when-you-turn-on-your-computer/',
                        name: "Blog"
                    }
                ]
            },
            {
                date: '28/09/2019',
                topic: 'Assembly Language: Overview',
                resources: [
                    {
                        link: 'http://www.brokenthorn.com/Resources/OSDevIndex.html',
                        name: 'Blog'
                    }
                ]
            },
            {
                date: '09/10/2019',
                topic: 'Bootloaders',
                resources: [
                    {
                        link: 'http://www.brokenthorn.com/Resources/OSDevIndex.html',
                        name: 'Blog'
                    }
                ]
            },
            {
                date: '05/02/2020',
                topic: 'OS Development - Bootloader',
                resources: [
                    {
                        link: 'https://www.howtogeek.com/398493/what-exactly-happens-when-you-turn-on-your-computer/',
                        name: 'Blog'
                    }

                ]
            },
        ]
    },
    {
        holder: 'Networks',
        discussions: [
            {
                date: '06/09/2019',
                topic: 'Bandit: Levels 1-10',
                resources: [
                    {
                        link: 'https://overthewire.org/wargames/bandit/',
                        name: 'Bandit Wargame'
                    }
                ]
            },
            {
                date: '24/09/2019',
                topic: 'Bandit: Levels 11-14',
                resources: [
                    {
                        link: 'https://overthewire.org/wargames/bandit/',
                        name: 'Bandit Wargame'
                    }
                ]
            },
            {
                date: '02/10/2019',
                topic: 'Bandit: Levels 15-22',
                resources: [
                    {
                        link: 'https://overthewire.org/wargames/bandit/',
                        name: 'Bandit Wargame'
                    }
                ]
            },
            {
                date: '11/10/2019',
                topic: 'Bandit: Levels 23-28',
                resources: [
                    {
                        link: 'https://overthewire.org/wargames/bandit/',
                        name: 'Bandit Wargame'
                    }
                ]
            },
            {
                date: '16/10/2019',
                topic: 'Bandit: Levels 29-33',
                resources: [
                    {
                        link: 'https://overthewire.org/wargames/bandit/',
                        name: 'Bandit Wargame'
                    }
                ]
            },
            {
                date: '01/02/2020',
                topic: 'Basics of Networking',
                resources: [
                    
                ]
            },
            {
                date: '09/02/2020',
                topic: 'Networking: Data Link Layer',
                resources: [
                    
                ]
            }
        ]
    },
    {
        holder: 'VLG',
        discussions: [
            {
                date: '12/08/2019',
                topic: 'CVPR 2019 Papers',
                resources: [
                    {
                        link: 'http://openaccess.thecvf.com/CVPR2019.py',
                        name: 'CVPR 2019'
                    }
                ]
            },
            {
                date: '23/08/2019',
                topic: 'ICML 2019 Workshop Papers',
                resources: [
                    {
                        link: 'https://icml.cc/Conferences/2019/Schedule?type=Workshop',
                        name: 'Workshop Papers'
                    }
                ]
            },
            {
                date: '28/08/2019',
                topic: 'ICML 2019 Main Conference Papers',
                resources: [
                    {
                        link: 'https://icml.cc/Conferences/2019/Schedule?type=Poster',
                        name: 'Conference Papers'
                    }
                ]
            },
            {
                date: '05/09/2019',
                topic: 'Summer 19 Project Presentations',
                resources: [
                    
                ]
            },
            {
                date: '25/09/2019',
                topic: 'Markov Decision Process',
                resources: [
                    {
                        link: 'https://youtu.be/2pWv7GOvuf0',
                        name: 'Lecture Video'
                    }
                ]
            },
            {
                date: '30/09/2019',
                topic: 'Linear Algebra (Deep Learning Book)',
                resources: [
                    {
                        link: 'https://www.deeplearningbook.org/contents/linear_algebra.html',
                        name: 'Book Chapter Link'
                    }
                ]
            },
            {
                date: '07/10/2019',
                topic: 'Planning by Dynammic Programming',
                resources: [
                    {
                        link: 'https://youtu.be/Nd1-UUMVfz4',
                        name: 'Lecture Video'
                    }
                ]
            },
            {
                date: '10/10/2019',
                topic: 'Model-Free Prediction',
                resources: [
                    {
                        link: 'https://youtu.be/PnHCvfgC_ZA',
                        name: 'Lecture Video'
                    }
                ]
            },
            {
                date: '16/10/2019',
                topic: 'Information Theory (Deep Learning Book)',
                resources: [
                    {
                        link: 'https://www.deeplearningbook.org/contents/prob.html',
                        name: 'Chapter Link'
                    }
                ]
            },
            {
                date: '21/10/2019',
                topic: 'Model Free Control',
                resources: [
                    {
                        link: 'https://youtu.be/0g4j2k_Ggc4',
                        name: 'Lecture Video'
                    }
                ]
            },
            {
                date: '23/10/2019',
                topic: 'Basic CNN Architectures',
                resources: [
                    {
                        link: 'https://towardsdatascience.com/cnn-architectures-a-deep-dive-a99441d18049',
                        name: 'Blog'
                    }
                ]
            },
            {
                date: '01/11/2019',
                topic: 'David Siver Lecture 6 - Value Function Approximation ',
                resources: [
                    {
                        link: 'https://youtu.be/UoPei5o4fps',
                        name: 'Lecture Video'
                    }
                ]
            },
            {
                date: '06/11/2019',
                topic: 'RNNs, LSTMs, GRUs and Introduction to Transformers',
                resources: [
                    {
                        link: 'https://colah.github.io/posts/2015-08-Understanding-LSTMs/',
                        name: 'Blog'
                    }
                ]
            },
            {
                date: '05/02/2020',
                topic: 'PyTorch Basics',
                resources: [
                    {
                        link: 'https://pytorch.org/tutorials/beginner/deep_learning_60min_blitz.html',
                        name: 'PyTorch Tutorial'
                    }
                ]
            },
            {
                date: '09/02/2020',
                topic: 'Adversarial Examples and Scene Graphs',
                resources: [
                    
                ]
            },
            {
                date: '12/02/2020',
                topic: 'Regularization Techniques, Dropout, Batch Normalization',
                resources: [
                    {
                        link: 'https://towardsdatascience.com/regularization-techniques-a-list-down-70df0e8693c',
                        name: 'Blog'
                    },
                    
                    {
                        link: 'https://www.cs.toronto.edu/~hinton/absps/JMLRdropout.pdf',
                        name: 'Dropout Paper'
                    },
                    
                    {
                        link: 'https://arxiv.org/abs/1502.03167',
                        name: 'BatchNorm Paper'
                    },
                ]
            },
            {
                date: '14/02/2020',
                topic: 'Recent Tier-1 Conferences\' Paper Discussions',
                resources: [
                    
                ]
            },
            {
                date: '19/02/2020',
                topic: 'RCNN and Fast RCNN',
                resources: [
                    {
                        link: 'https://arxiv.org/abs/1311.2524',
                        name: 'RCNN Paper'
                    },
                    {
                        link: 'https://arxiv.org/abs/1504.08083',
                        name: 'Fast RCNN Paper'
                    }
                ]
            },
            {
                date: '21/02/2020',
                topic: 'Adversarial Examples and Scene Graphs',
                resources: [
                    
                ]
            },
            {
                date: '25/02/2020',
                topic: 'Faster RCNN, Mask RCNN and YOLO',
                resources: [
                    {
                        link: 'https://arxiv.org/abs/1506.01497',
                        name: 'Faster RCNN Paper'
                    },
                    {
                        link: 'https://arxiv.org/abs/1703.06870',
                        name: 'Mask RCNN Paper'
                    },
                    {
                        link: 'https://arxiv.org/abs/1506.02640',
                        name: 'YOLO Paper'
                    }
                ]
            },
        ]
    }
]