import sateesh from './../assets/images/team/satish_sir.jpg';

import aarush from './../assets/images/team/aarush.jpeg';
import dakshit from './../assets/images/team/dakshit.jpeg';
import patra from './../assets/images/team/patra.jpeg';
import rahul from './../assets/images/team/rahul.jpeg';
import akshat from './../assets/images/team/akshat.jpeg';
import sachin from './../assets/images/team/sachin.jpeg';
import aryaman from './../assets/images/team/aryaman.jpeg';
import vipul from './../assets/images/team/vipul.jpg';

import chaubey from './../assets/images/team/chaubey.png';
import roodram from './../assets/images/team/roodram.jpg';
import amanjaiswal from './../assets/images/team/aman-jaiswal.jpg';
import amanverma from './../assets/images/team/aman-verma.jpeg';
import aniketAggarwal from './../assets/images/team/aniket-agarwal.jpg';
import gopiKishan from './../assets/images/team/gopi-kishan.jpeg';
import mansi from './../assets/images/team/mansi.jpg';
import siddhant from './../assets/images/team/siddhant.jpg';
import ghetia from './../assets/images/team/ghetia.jpg';

import anmolkhemka from './../assets/images/team/anmolkhem.jpeg';
import dikshachaudhary from './../assets/images/team/dikshachaudhary.jpg';
import masih from './../assets/images/team/masih.jpg';
import karansingh from './../assets/images/team/karansingh.jpg';
import priyanshagarwal from './../assets/images/team/priyanshagarwal.jpg';
import rishi from './../assets/images/team/rishi.jpg';
import suraaj from './../assets/images/team/suraajks.jpg';

import gyanvaibhav from './../assets/images/team/gyanvaibhav.jpg';
import pranaw from './../assets/images/team/pranavraj.jpg';
import rahulagarwal from './../assets/images/team/rahulaggarwal.jpg';


export const teamList = {
    advisor: [
        {
          "first_name": "P. Sateesh",
          "last_name": " Kumar",
          "mail": "drpskfec@iitr.ac.in",
          "github": "",
          "position": "",
          "fb": "",
          "image": sateesh,
          "website": "http://www.peddoju.com/",
          "linkedin": "",
          "twitter": "",
        },
    ],
    bearers: [
        {
            "first_name": "Aniruddha",
            "last_name": "Mahapatra",
            "mail": "amahapatra@cs.iitr.ac.in",
            "github": "anime26398",
            "fb": "aniruddha.mahapatra",
            "position": "Chair",
            "image": patra,
            "website": "",
            "linkedin": "",
            "twitter": "",
          },
          {
            "first_name": "Aarush",
            "last_name": "Gupta",
            "mail": "agupta1@cs.iitr.ac.in",
            "github": "aarushgupta",
            "position": "Vice Chair",
            "fb": "aarush.363",
            "image": aarush,
            "website": "",
            "linkedin": "",
            "twitter": "",
          },
          {
            "first_name": "Dakshit",
            "last_name": "Agrawal",
            "mail": "dagrawal@cs.iitr.ac.in",
            "github": "dakshitagrawal97",
            "fb": "neelagrawal08",
            "image": dakshit,
            "website": "https://dakshitagrawal97.github.io/",
            "linkedin": "https://www.linkedin.com/in/dakshit97/",
            "twitter": "@dakshit_97",
            "position": "Treasurer"
          }
    ],
    heads: [
        {
            "first_name": "Akshat",
            "last_name": "Arora",
            "mail": "aarora@cs.iitr.ac.in",
            "github": "AkshatArora",
            "fb": "akshataroraarora.arora",
            "image": akshat,
            "website": "",
            "linkedin": "",
            "twitter": "",
            "position": "Backend Head"
          },
          {
            "first_name": "Aryaman",
            "last_name": "Maheshwari",
            "mail": "amaheshwari1@cs.iitr.ac.in",
            "github": "vaham-7",
            "fb": "aryaman.maheshwari.7",
            "image": aryaman,
            "website": "",
            "linkedin": "",
            "twitter": "",
            "position": "Events Head"
          },
          {
            "first_name": "Rahul",
            "last_name": "Singh",
            "mail": "rsingh@cs.iitr.ac.in",
            "github": "Rsingh2805",
            "fb": "rahul.singh.280599",
            "image": rahul,
            "website": "",
            "linkedin": "",
            "twitter": "",
            "position": "Web Dev Head"
          },
          {
            "first_name": "Sachin",
            "last_name": "Jain",
            "mail": "sachinjain3677@gmail.com",
            "github": "sachinjain3677",
            "fb": "",
            "image": sachin,
            "website": "",
            "linkedin": "",
            "twitter": "",
            "position": "OS Discussion Head"
          },
          {
            "first_name": "Vipul",
            "last_name": "Jain",
            "mail": "vjain@cs.iitr.ac.in",
            "github": "VipulJai",
            "fb": "vipul.ja1n",
            "image": vipul,
            "website": "",
            "linkedin": "",
            "twitter": "",
            "position": "Networks Discussion Head"
          },
    ],
    members: [
        {
            "first_name": "Aaryan",
            "last_name": "Jain",
            "mail": "ajain@cs.iitr.ac.in",
            "github": "aary0510",
            "fb": "Aary0510",
            "image": "",
            "website": "",
            "linkedin": "",
            "twitter": "",
            "position": ""
          },
          {
            "first_name": "Aman",
            "last_name": "Jaiswal",
            "mail": "ajaiswal@cs.iitr.ac.in",
            "github": "jaiswalaman",
            "fb": "amanjaiswal815",
            "image": amanjaiswal,
            "website": "",
            "linkedin": "",
            "twitter": "",
            "position": ""
          },
          {
            "first_name": "Aman",
            "last_name": "Verma",
            "mail": "averma@cs.iitr.ac.in",
            "github": "amanverma7",
            "fb": "aman08verma12",
            "image": '',
            "website": "",
            "linkedin": "",
            "twitter": "",
            "position": ""
          },
          {
            "first_name": "Aniket",
            "last_name": "Agarwal",
            "mail": "aagarwal@ma.iitr.ac.in",
            "github": "aniket-agarwal1999",
            "fb": "aniket.agarwal.9484941",
            "image": aniketAggarwal,
            "website": "",
            "linkedin": "",
            "twitter": "",
            "position": ""
          },
          {
            "first_name": "Ashutosh",
            "last_name": "Chaubey",
            "mail": "achaubey@cs.iitr.ac.in",
            "github": "ac-alpha",
            "fb": "ashutosh.chaubey.original",
            "image": chaubey,
            "website": "",
            "linkedin": "",
            "twitter": "",
            "position": ""
          },
          {
            "first_name": "Gaurav",
            "last_name": "Dewat",
            "mail": "gdewat@cs.iitr.ac.in",
            "github": "gauravdewat",
            "fb": "dewatgaurav",
            "image": "",
            "website": "",
            "linkedin": "",
            "twitter": "",
            "position": ""
          },
          {
            "first_name": "Gopi",
            "last_name": "Kishan",
            "mail": "gkishan@cs.iitr.ac.in",
            "github": "GopiKishan14",
            "fb": "gopi.kishan.17",
            "image": gopiKishan,
            "website": "",
            "linkedin": "",
            "twitter": "",
            "position": ""
          },
          {
            "first_name": "Mansi",
            "last_name": "Agarwal",
            "mail": "magarwal@cs.iitr.ac.in",
            "github": "Mansi449",
            "fb": "MansiAgarwal449",
            "image": mansi,
            "website": "",
            "linkedin": "",
            "twitter": "",
            "position": ""
          },
          {
            "first_name": "Roodram",
            "last_name": "Paneri",
            "mail": "rpaneri@cs.iitr.ac.in",
            "github": "Roodram",
            "fb": "roodram",
            "image": roodram,
            "website": "",
            "linkedin": "",
            "twitter": "",
            "position": ""
          },
          {
            "first_name": "Siddhant",
            "last_name": "Nayak",
            "mail": "snayak@cs.iitr.ac.in",
            "github": "toadchavay",
            "fb": "siddhant.nayak.100",
            "image": siddhant,
            "website": "",
            "linkedin": "",
            "twitter": "",
            "position": ""
          },
          {
            "first_name": "Ghetia",
            "last_name": "Siddharth",
            "mail": "gsiddharth@cs.iitr.ac.in",
            "github": "gsid1",
            "fb": "siddharth.ghetia.5",
            "image": ghetia,
            "website": "",
            "linkedin": "",
            "twitter": "",
            "position": ""
          },
          {
            "first_name": "Vishal",
            "last_name": "Garg",
            "mail": "vgarg@cs.iitr.ac.in",
            "github": "Vishalgarg425",
            "fb": "",
            "image": "",
            "website": "",
            "linkedin": "",
            "twitter": "",
            "position": ""
          },
          {
            "first_name": "Anmol",
            "last_name": "Khemuka",
            "mail": "akhemuka@cs.iitr.ac.in",
            "github": "anmolkhemuka",
            "fb": "Anmolkhemuka8",
            "image": anmolkhemka,
            "website": "",
            "linkedin": "",
            "twitter": "",
            "position": ""
          },
          {
            "first_name": "Diksha",
            "last_name": "Chaudhary",
            "mail": "diksha@cs.iitr.ac.in",
            "github": "diksha628",
            "fb": "diksha.chaudhary.986227",
            "image": '',
            "website": "",
            "linkedin": "",
            "twitter": "",
            "position": ""
          },
          {
            "first_name": "Karan",
            "last_name": "Singh",
            "mail": "ksingh1@cs.iitr.ac.in",
            "github": "KaranKS",
            "fb": "karan976",
            "image": karansingh,
            "website": "",
            "linkedin": "",
            "twitter": "",
            "position": ""
          },
          {
            "first_name": "Masih",
            "last_name": "Ahmed",
            "mail": "mahmed@cs.iitr.ac.in",
            "github": "anon766",
            "fb": "masih.ahmed.3",
            "image": masih,
            "website": "",
            "linkedin": "",
            "twitter": "",
            "position": ""
          },
          {
            "first_name": "Priyansh",
            "last_name": "Agarwal",
            "mail": "pagarwal@cs.iitr.ac.in",
            "github": "priyanshagarwal18",
            "fb": "priyansh.agarwal.7",
            "image": priyanshagarwal,
            "website": "",
            "linkedin": "",
            "twitter": "",
            "position": ""
          },
          {
            "first_name": "Priyanshu",
            "last_name": "Garg",
            "mail": "pgarg1@cs.iitr.ac.in",
            "github": "priyanshu219",
            "fb": "priyanshugarg",
            "image": "",
            "website": "",
            "linkedin": "",
            "twitter": "",
            "position": ""
          },
          {
            "first_name": "Rishi",
            "last_name": "Chordia",
            "mail": "rchordia@cs.iitr.ac.in",
            "github": "rishichordia",
            "fb": "rishi.chordia.5",
            "image": rishi,
            "website": "",
            "linkedin": "",
            "twitter": "",
            "position": ""
          },
          {
            "first_name": "Suraaj",
            "last_name": "K S",
            "mail": "skanniwadi@cs.iitr.ac.in",
            "github": "kssuraaj28",
            "fb": "",
            "image": suraaj,
            "website": "",
            "linkedin": "",
            "twitter": "",
            "position": ""
          },
          {
            "first_name": "Ghanshyam",
            "last_name": "Singh",
            "mail": "ghanshyam_s@me.iitr.ac",
            "github": "jarvis-001",
            "fb": "ghanshyam singh moyal",
            "image": "",
            "website": "",
            "linkedin": "",
            "twitter": "",
            "position": ""
          },
          {
            "first_name": "Gyan",
            "last_name": "Vaibhav",
            "mail": "gyan_v@ec.iitr.ac.in",
            "github": "AntiEXE",
            "fb": "gyan.vaibhav",
            "image": gyanvaibhav,
            "website": "",
            "linkedin": "",
            "twitter": "",
            "position": ""
          },
          {
            "first_name": "Pranaw",
            "last_name": "Raj",
            "mail": "pranaw_r@cs.iitr.ac.in",
            "github": "pranaw154",
            "fb": "pranaw.raj.509",
            "image": pranaw,
            "website": "",
            "linkedin": "",
            "twitter": "",
            "position": ""
          },
          {
            "first_name": "Rahul",
            "last_name": "Agrawal",
            "mail": "rahul_a@cs.iitr.ac.in",
            "github": "rahulag2411",
            "fb": "rahul.raga.2411",
            "image": rahulagarwal,
            "website": "",
            "linkedin": "",
            "twitter": "",
            "position": ""
          }
    ]
}