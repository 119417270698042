import React from 'react';
import {Container, Card, Button, Row, Col} from 'react-bootstrap';
import {blogList} from './../../assets/blogs';
import './index.scss';

const BlogCard = props => {
    return (
        <Card className="blog-card hover-box">
            <Card.Img variant="top" src={props.blog.image}/>
            <Card.Body>
                <Card.Title>{props.blog.title}</Card.Title>

                <div className="blog-date mb-2">{props.blog.date}</div>
                <div className="blog-text mb-2">{props.blog.text}</div>
                <div className="blog-author">By {props.blog.author}</div>
                
                
            </Card.Body>
            <Button variant="primary" href={props.blog.link}>Follow Link</Button>
        </Card>
    )
}

export const BlogComponent = props => {
    return (
        <React.Fragment>
            <Container id="blog-container">
                <h3>Blogs</h3>
                <Row>
                    {
                        blogList.map((obj, i) => {
                            return (
                                <Col key={i} xs={12} sm={6} lg={4} className={"px-4 py-2"}>
                                    <BlogCard blog={obj}/>
                                </Col>
                            )
                        })
                    }
                </Row>
            </Container>
        </React.Fragment>
        
    )
}